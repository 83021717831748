






























































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import ImContent from './im_content.vue'
import ImContentExpert from './im_content_expert.vue'
export default Vue.extend({
    components: {
        ImContent,
        ImContentExpert,
    },
    data() {
        return {
            oppositeId: '', // 目标ID，即专家ID
            fromId: '',
            toId: '',
            loading: false,
            pageNum: 0,
            pageSize: 8,
            total: 0,
            chatListDefault: [],
            chatList: [],
            title: '',
            opposite: {} as any,
            chatMode: 1,
            text: '',
            currentToken: this.$store.state.token,
            imgShow: false,
            imgUrl: '',
            imgcheck: false,
            imgcheckUrl: '',
            headimgurl: '',
            // isExpert: false,
        }
    },
    computed: {
        current(): any {
            return this.$store.state.current
        },
        managerInfo(): any {
            return this.$store.state.managerInfo
        },
        isExpert(): boolean {
            return this.toId === this.managerInfo.id
            // return this.current.role == 1
        },
    },
    created() {
        this.oppositeId = this.$route.params.id
        // console.log(this.$route.query.headimgurl)
        this.headimgurl = this.$route.query.headimgurl
        this.toId = this.$route.query.toId
        this.fromId = this.$route.query.fromId

    },
    mounted() {
        this.getOpposite()
        this.onInit()
    },
    methods: {
        checkImg(data) {
            // console.log(data)
            this.imgcheck = true
            this.imgcheckUrl = data
        },
        async getOpposite() {
            if(this.isExpert) {
                request.get(`/api/account/rest_account/${this.oppositeId}`).then((acco: any) => {
        
                    this.title = acco.realName
                    this.opposite = acco
                })
            } else {
                let relationData = {}as any
                await request.get(`/api/tobacco/manager/info/${this.oppositeId}`).then((res: any)=> {
                    // console.log(res)
                    relationData = res
                    // this.opposite = res
                    // this.title = res.name
                })
                // console.log(relationData)

              const currentId = this.current.id
            
              if(currentId === relationData.relationId){
                this.isExpert = true
              }
               
                this.opposite = relationData
                this.title = relationData.name

                if(!this.headimgurl) {
                    // console.log(this.opposite.relationId)
                    const obj = await this.getExpertImg(relationData, relationData.relationId)
                    this.$set(this.opposite, 'headimgurl', obj)

                }
            }
        },
        getExpertImg(expertChat, relationId) {
            let headimgurl = ''
            return request.get(`/api/account/rest_account/${relationId}`).then(res => {
                if (res) {
                    headimgurl = res.headimgurl
                } else {
                    if (expertChat.sex === 1) {
                        headimgurl = '/static/images/sex_1.png'
                    } else if (expertChat.sex === 2) {
                        headimgurl = '/static/images/sex_2.png'
                    } else {
                        headimgurl = '/static/images/sex_0.png'
                    }
                }
                if(headimgurl ===''){
                  headimgurl = '/static/images/sex_0.png'
                }
                return headimgurl
            })
        },
        onInit() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },
     
        async getData() {
            // this.loading = true
            const { oppositeId, pageNum, pageSize } = this

            const { role } = this.current
            let expertId = ''
            if( role == 1)  expertId = this.managerInfo?.id
        
            const params = { pageNum, pageSize, toId: oppositeId, expertId }
            await request.get('/api/chat/rest_chat/page', params).then(({list, total})=> {
                if(pageNum==1) this.chatListDefault = list.reverse()
                else this.chatListDefault = list.reverse().concat(this.chatListDefault)
                // console.log(this.chatListDefault)
                const defaultData = JSON.parse(JSON.stringify(this.chatListDefault))
                // console.log(defaultData)
                defaultData.map((e) => {
                    e.timeShow = true
                    e.timestr = Number(moment(e.createTime).format('YYYYMMDDHHmm'))
                })
                // console.log(list)
                if(defaultData.length > 1) {
                    defaultData.map((e, i) => {
                        if(i < defaultData.length - 1) {
                            // console.log(defaultData[i].timestr)
                            // console.log(defaultData[i + 1].timestr)
                            // console.log('-------------')
                            if(defaultData[i].timestr === defaultData[i + 1].timestr) {
                                // console.log('===')
                                defaultData[i + 1].timeShow = false
                            } else {
                                // console.log('!==')
                                defaultData[i + 1].timeShow = true
                            }
                            // console.log(defaultData[i].timeShow)
                            // console.log(defaultData[i + 1].timeShow)
                        } else {
                            // console.log('last one')
                            // console.log(defaultData[defaultData.length - 2].timestr)
                            // console.log(defaultData[defaultData.length - 1].timestr)
                            if(defaultData[defaultData.length - 2].timestr === defaultData[defaultData.length - 1].timestr) {
                                // console.log('===')
                                defaultData[defaultData.length - 1].timeShow = false
                            } else {
                                // console.log('!==')
                                defaultData[defaultData.length - 1].timeShow = true
                            }
                        }
                        // console.log('')
                    })
                }
                // console.log(defaultData)
                this.chatList = defaultData
                // if(pageNum==1) this.chatList = list.reverse()
                // else this.chatList = list.reverse().concat(this.chatList)
                this.total = total
                // 滚动到地图显示区域
                this.$nextTick(() => {
                    const listTmp: any = this.$refs.list
                    if(pageNum==1) listTmp.scrollTop = listTmp.scrollHeight
                })
                setTimeout(()=>this.loading = false, 100)
            })
        },
        send() {
            const { role } = this.current
            let expertId = '' //  默认fromId取当前账户ID，当前账户为专家时，应为专家ID
            if( role == 1)  {
                expertId = this.managerInfo?.id
            }
            //  默认发送消息到toID，toID为对方的ID，当对方有关联ID时，将关联的relationID传到后台用于推送消息
            const params = { toId: this.oppositeId, fromRole: role, expertId, relationId: this.opposite?.relationId }as any
            if (this.text) {
                params.chatMode = 1
                params.content = this.text
            } else {
                const arr = ['jpg','jpeg','png']
                const str = this.imgUrl.split('.')[1]
                if (arr.indexOf(str) > -1) {
                    params.chatMode = 2
                } else if (str === 'mp4' || str === 'MOV') {
                    params.chatMode = 3
                } else {
                    params.chatMode = 4
                }
                params.content = this.imgUrl
            }
            // console.log(params)
            request.post('/api/chat/rest_chat', params)
            .then(()=>{
                this.onInit()
                this.text = ''
            })
            .catch(e => this.$notify(e))
        },
        submit(data) {
            this.imgShow = false
            if (data) {
                this.send()
            }
            this.imgUrl = ''
        },
        handleSuccess(response: any) {
            // console.log(response)
            this.imgShow = true
            this.imgUrl = response
            this.$Message.success('上传完成')
        },
        handleError(res: any) {
            console.log(res)
            this.$Message.error('上传失败，请重试')
        },
        handleFormatError(file: any) {
            this.$Notice.warning({
                title: '文件格式有误',
                desc: '文件' + file.name + '格式有误, 文档请选择jpg,jpeg,png或mp4格式文件',
            });
        },
        handleRemove() {
            // this.formData.url = ''
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            if(isToday(time)) {
                return time.format('HH:mm')
            }
            return time.format('YYYY年M月D日 HH:mm')
        }
    }
})
function isToday(momentDate: moment.Moment) {
    return momentDate.isSame(moment().startOf('day'), 'd')
}
